<template>
  <div class="overall">
    <!-- 上半部分 -->
    <div class="upside">
      <!-- 上半部分左边内容 -->
      <div class="inventory">
        <!-- 左半部分上边内容 -->
        <div class="message">
          <!-- 头像 -->
          <div class="stration">
            <div>
              <el-avatar :size="120" src="https://empty" @error="errorHandler">
                <img
                  src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"
                />
              </el-avatar>
            </div>
            <!-- 用户名字 -->
            <div class="username">
              <div class="name">admin</div>
              <div class="super">超级管理员</div>
            </div>
          </div>
          <div>
            <!-- 登陆时间 -->
            <div class="tiem">
              <div class="quantum">上次登录时间：</div>
              <div>2022-4-24</div>
            </div>
            <!-- 登陆地点 -->
            <div class="tiem">
              <div class="quantum">上次登录地点：</div>
              <div>北京</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 上半部分右边内容 -->
      <div class="plane">
        <!-- 右边头部 -->
        <div class="ritherd">
          <el-col :span="8">
            <div class="impose">
              <el-card class="a" shadow="hover">
                <div class="amount">
                  <div class="union">
                    <el-icon size="35px" color="#ffffff"><school /></el-icon>
                  </div>
                  <div class="magnitude">
                    <!-- <div class="count">123</div> -->
					<div class="count">0</div>
                    <div class="crew">工会数量</div>
                  </div>
                </div>
              </el-card>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="impose1">
              <el-card shadow="hover">
                <div class="amount">
                  <div class="union1">
                    <el-icon size="35px" color="#ffffff"
                      ><UserFilled
                    /></el-icon>
                  </div>
                  <div class="magnitude">
                    <!-- <div class="count1">123</div> -->
					<div class="count1">0</div>
                    <div class="crew">人员数量</div>
                  </div>
                </div>
              </el-card>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="impose1">
              <el-card shadow="hover">
                <div class="amount">
                  <div class="union2">
                    <el-icon size="35px" color="#ffffff"><Coin /></el-icon>
                  </div>
                  <div class="magnitude">
                   <!-- <div class="count2">123</div> -->
					<div class="count2">0</div>
                    <div class="crew">积分数量</div>
                  </div>
                </div>
              </el-card>
            </div>
          </el-col>
        </div>
        <!-- 右半部分下边内容 -->
        <div class="below">
          <el-card class="box-card" shadow="hover">
            <template #header>
              <div class="card-header">
                <span>待办事项</span>
              </div>
            </template>
            <!-- 待办事项内容 @cell-click="backlog" -->
            <el-table
              :data="tableData"
              style="width: 100%"
              :show-header="false"
              
            >
              <el-table-column width="120">
                <template #default="scope">
                  {{ scope.row.date }}
                </template>
              </el-table-column>
            </el-table>
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { School, UserFilled, Coin } from "@element-plus/icons-vue";
import { useRouter } from "vue-router"
import {ref} from "vue"
export default {
  setup() {
    // 待办内容点击事件
    const router = useRouter()
    const backlog = (row)=>{
      router.push({path:'/homes'}) 
      console.log(row.date)
    }
    // 待办内容
    const tableData = [
      // {
      //   id:0,
      //   date: "修复的主要内容",
      // },
      // {
      //   date: "2016-05-02",
      // },
      // {
      //   date: "2016-05-04",
      // },
      // {
      //   date: "2016-05-01",
      // },
      // {
      //   date: "2016-05-08",
      // },
      // {
      //   date: "2016-05-06",
      // },
      // {
      //   date: "2016-05-07",
      // },
    ];
    // 头像加载失败
    const errorHandler = () => true;
    return {
      errorHandler,
      tableData,
      backlog
    };
  },
  components: {
    School,
    UserFilled,
    Coin,
  },
};
</script>
<style lang='scss' scoped>
::v-deep .el-avatar{
  --el-avatar-bg-color:	#F5F5F5;
}
// 右半部分下边内容
::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell{
  cursor:pointer;
}
::v-deep .el-checkbox__label {
  padding-left: 25px;
}
.el-table__body,
.el-table__footer,
.el-table__header {
  width: 96% !important;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 20px;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.box-card {
  width: 480px;
}
// 右半部分上边内容
.impose2 {
  margin-left: 20px;
}
.impose1 {
  margin-left: 20px;
}
.crew {
  font-size: 14px;
  color: #999;
}
.count1 {
  font-size: 30px;
  font-weight: bold;
  color: rgb(100, 213, 114);
}
.count2 {
  font-size: 30px;
  font-weight: bold;
  color: rgb(242, 94, 67);
}
.count {
  font-size: 30px;
  font-weight: bold;
  color: rgb(45, 140, 240);
}
.magnitude {
  width: 71%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.el-card {
  width: 99%;
}
.amount {
  display: flex;
  flex-direction: row;
}
.ritherd {
  width: 99.3%;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
.union1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  background-color: rgb(100, 213, 114);
}
.union2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  background-color: rgb(242, 94, 67);
}
.union {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  background-color: rgb(45, 140, 240);
}
.el-card__body {
  padding: 0;
}
.plane {
  width: 85%;
  margin-left: 20px;
}
// 左半部分上边内容
@media screen and (max-width: 1200px) {
  .tiem {
    display: none !important;
  }
}
.quantum {
  margin-right: 70px;
}
.tiem {
  font-size: 14px;
  color: #999;
  line-height: 25px;
  display: flex;
  align-items: center;
}
.inventory {
  width: 40%;
  overflow: hidden;
  border-radius: 4px;
}
.username {
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .name {
    font-size: 30px;
    color: #222;
  }
  .super {
    font-size: 14px;
    color: #999;
  }
}
.stration {
  padding-bottom: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #ccc;
}
.message {
  background-color: #ffffff;
  padding: 20px;
}
// 上半部分内容
.upside {
  display: flex;
}
// 整体
.overall {
  padding-left: 10px;
  padding-top: 10px;
  // margin-top: 30px;
  height: 94%;
}
</style>